import React from 'react'
import styled from 'styled-components'
import Layout from '../../layouts'
import MetaHeader from '../../components/MetaHeader'
import PageWrapper from '../../components/PageWrapper'
import PageIntro from '../../components/PageIntro'
import TextBlock from '../../components/TextBlock'
import GridTwo from '../../components/GridTwo'
import HowItWorksContent from '../../components/angel-academy/HowItWorksContent'
import Button from '../../components/Button'

import { graphql } from 'gatsby'

const Wrapper = styled.div`
  margin-bottom: 2rem;
`
const CTA = styled.div`
  margin: 3rem 0;
`
const CTAButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`

const HowItWorks = ({ data }) => {
  const {
    contentfulAngelAcademyPage: {
      pageIntro: {
        heading,
        subheading,
        image,
        metaTitle,
        metaSiteUrl,
        metaDescription,
        metaKeywords,
        metaTwitter,
      },
    },
  } = data
  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />

      <Wrapper>
        <PageWrapper>
          <PageIntro
            title={heading}
            subtitle={subheading}
            imgSrc={image?.file?.url}
            alt={image?.title}
          />

          <HowItWorksContent />
        </PageWrapper>

        <PageWrapper>
          <CTA>
            <TextBlock center>
              <GridTwo>
                <CTAButton to="/angel-academy#apply-now">Sign me up!</CTAButton>
                <CTAButton to="/angel-academy/who-can-join" outline>
                  Am I a fit?
                </CTAButton>
              </GridTwo>
            </TextBlock>
          </CTA>
        </PageWrapper>
      </Wrapper>
    </Layout>
  )
}

export default HowItWorks

export const query = graphql`
  query {
    contentfulAngelAcademyPage(
      id: { eq: "c3c34c7f-b1de-5014-aef6-16ab2d89764d" }
    ) {
      pageIntro {
        heading
        subheading
        image {
          file {
            url
          }
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
        metaSiteUrl
        metaTwitter
      }
    }
  }
`
